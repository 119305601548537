* {
  font-family: "Roboto", sans-serif;
}

//colors
@default-bg: #f8f8f8;
@grey: #dddddd;
@grey-dark: #777777;
@grey-lighter: #f9f9f9;
@grey-light: #eeeeee;
@orange: #e29578;
@orange-light: #ffddd2;
@blue: #83b8c5;
@blue-light: #edf6f9;

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-mont;
  @apply text-grey-dark;
}

svg.ionicon {
  color: @grey-light;
  fill: @grey-light;
  stroke: @grey-light;
}

h1,
h2,
h3,
h4,
p,
td,
label,
li {
  @apply text-grey-dark;
}
h1 {
  @apply font-semibold text-3xl text-grey-dark leading-9;
}

h2 {
  @apply font-semibold text-2xl text-grey-dark;
}
h3 {
  @apply font-semibold text-base text-grey-dark;
}
h4 {
  @apply uppercase text-base;
}
label {
  @apply text-base text-grey-dark;
}

.list-item:last-child {
  @apply rounded-b-2xl;
}

input,
textarea {
  -webkit-appearance: none;
  @apply text-grey-dark bg-transparent rounded-2xl border border-grey py-4 px-6 w-full;
  &::placeholder {
    @apply text-grey-dark opacity-50 transition-opacity;
  }
  &:focus {
    @apply border-blue;
    .focus-shadow;
    &::placeholder {
      @apply opacity-100 transition-opacity;
    }
  }
  &.error {
    @apply border-orange border-2;
    width: calc(100% - 0.5px);
    &:focus {
      .error-focus-shadow;
    }
  }
}
textarea {
  resize: none;
}
input,
select,
button {
  -webkit-appearance: none;
  outline: none !important;
}
.sort {
  &:hover {
    .sort-arrow {
      @apply opacity-100;
    }
  }
}
.sort-arrow {
  @apply opacity-25;
  &:hover,
  &.active {
    @apply opacity-100;
  }
}
.tab {
  @apply pt-4 pb-2 font-mont font-medium text-center border border-grey cursor-pointer bg-grey-lighter transition-colors;
  &:first-child {
    @apply rounded-t-2xl !important;
  }
  &:last-child {
    @apply rounded-tr-2xl !important;
  }
  & + .tab  {
    @apply rounded-tl-2xl rounded-tr-2xl !important;
  }
  & + .selected-tab {
    @apply rounded-t-2xl !important;
  }
  &:hover,
  &:focus {
    @apply bg-grey-light transition-colors;
  }
}
.selected-tab {
  @apply bg-white border-b-0 rounded-t-2xl text-blue z-4 px-0 !important;
  & ~ .tab {
    @apply rounded-t-none;
  }
  &:hover,
  &:focus {
    @apply bg-white;
  }
}
.order-grid {
  grid-template-columns: 2fr 2fr 1fr 2fr 1fr;
  p {
    @apply text-xs px-4 py-4 text-right;
    &.left {
      @apply text-left !important;
    }
    &.base {
      @apply text-base !important;
    }
    &.details {
      grid-column-start: 2;
      grid-column-end: 6;
      text-align: left;
    }
  }
}
.order-grid-price {
  grid-template-columns: 3fr 3fr 5fr;
  p {
    @apply pr-6 py-4;
  }
}
.grid-doors {
  p {
    @apply text-xs px-6 py-4 text-left;
    &.base {
      @apply text-base !important;
    }
    &.right {
      @apply text-right !important;
    }
  }
}
.date-picker {
  @apply absolute p-3 right-0 mr-6 h-12 w-12 !important;
  top: -1.5rem;
}
.stats-grid {
  grid-template-columns: 5fr 2fr 2fr;
  p {
    @apply py-2 text-xs text-right pr-4;
    &.left {
      @apply pl-4 text-left !important;
      @media (min-width: theme("screens.md")) {
        @apply pl-7 !important;
      }
    }
    &.right {
      @apply pr-4 !important;
      @media (min-width: theme("screens.md")) {
        @apply pr-7 !important;
      }
    }
  }
}

input[name="doors"]:checked + label {
  @apply border-blue bg-blue-light text-blue;
}
input[name="tracks"]:checked + label {
  @apply border-blue bg-blue-light text-blue;
}
input[name="lighting"]:checked + label {
  @apply border-blue bg-blue-light text-blue;
}
input[name="placement"]:checked + label {
  @apply border-blue bg-blue-light text-blue;
}
.toggle-switch {
  div {
    position: relative;
    &:before {
      content: "";
      @apply absolute w-10 h-10 bg-white border border-grey rounded-full;
      left: 4px;
      transition: all 0.5s;
    }

    &:after {
      content: "Nej";
      @apply ml-auto mr-5 text-grey-dark;
    }
  }

  input:checked + div {
    @apply bg-blue-light border-blue;

    &:before {
      @apply border-blue transform -translate-x-full;
      left: calc(100% - 4px);
    }

    &:after {
      content: "Ja";
      @apply mr-auto ml-5;
    }
  }
}

.modal {
  @apply absolute bg-white rounded-2xl border border-grey items-center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//Error message
.error-msg {
  @apply text-orange border border-t-0 text-sm border-orange-light rounded-2xl flex items-center px-6 py-4 w-full;
}

.form-error {
  @apply rounded-t-none pt-7 -mt-4;
  .error-msg;
}

//transitions
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

//focus
.focus-shadow {
  box-shadow: 0px 0px 3px @blue;
}
.error-focus-shadow {
  box-shadow: 0px 0px 3px @orange-light;
}
.hover-focus {
  @apply transition-colors border-blue bg-blue-light text-blue;
}

// new & edit products
.new-edit-product {
  .p-row .label {
    @media (min-width: theme("screens.xl")) {
      @apply hidden;
    }
  }
}

@import "./components.less";
