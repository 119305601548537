//Buttons
.button {
  height: 50px;
  @apply border border-grey-light bg-white transition-all flex items-center justify-center font-medium;
  svg, p {
		@apply text-grey-dark;
		fill: @grey-dark;
	}
  &:hover, &:focus, &.active {
    .hover-focus;
    svg, p {
			@apply text-blue;
			fill: @blue;
		}
  }
	&.aligned {
		@apply rounded-2xl px-6 py-4 font-normal h-auto border-grey;
		&:hover, &:focus, &.active {
			.hover-focus;
		}
	}
	@media (min-width: theme('screens.lg')) {
		&.lg\:hidden {
			display: none !important;
		}
	}
}

.text-button {
  @apply px-6 rounded-full;
  .button;
  svg {@apply mr-4;}
	p {@apply whitespace-nowrap;}
}

.round-button {
  min-width: 50px;
	max-width: 50px;
  @apply rounded-full;
  .button;
}

.checkbox {
	@apply rounded-full w-8 h-8 border border-grey flex justify-center items-center;
	svg { color: transparent;}
	&:hover, &:focus {
		@apply border-blue bg-blue-light;
		.focus-shadow
		svg {color: @blue;}
	}
	&.selected {
		@apply border-blue bg-blue;
		svg {
			color: #fff;
		}
	}
}

//Input
.input {
	input {
		&[type="email"], &[type="tel"], &[type="search"] {
			padding-left: 3.5rem;
		}
		&[type="search"] {
			height: 50px;
			@apply rounded-full border-grey-light;
			&::placeholder {
				@apply opacity-100;
			}
			&:focus {
				@apply border-blue;
			}
		}
		&::-webkit-scrollbar {
			display: none;
		}
	}
	input:focus + .color {
		@apply border-blue;
	}
	svg.input-icon {
		@apply absolute w-6 ml-4;
		top: 50%;
		transform: translateY(-50%);
		&.search {
			@apply w-5 ml-5;
		}
		&.error {
			color: @orange;
		}
	}
	&:focus-within {
		svg.input-icon {
			fill: @blue;
			color: @blue;
			&.error {
				color: @orange;
			}
		}
	}
	&.disabled {
		input {
			@apply bg-grey-lighter;
		}
	}
}
.password, .repeat_password, .old_password {
	input {
		@apply bg-grey-lighter;
	}
	&.edit input {
		@apply bg-white;
	}
}

//textarea
textarea {
	&:focus {
		@apply border-blue outline-none;
	}
}

//Field 
.field {
	&::-webkit-scrollbar {
		display: none;
	}
	&.field-type-email, &.field-type-tel {
		padding-left: 1rem;
		p {
			padding-left: 2.5rem;
		}
	}
	svg.input-icon {
		@apply absolute w-6;
		top: 50%;
		transform: translateY(-50%);
		&.search {
			@apply w-5 ml-5;
		}
	}
}

//Select
.select-wrapper {
	&.header {
		@media (min-width: theme('screens.md')) {
			min-width: 180px;
		}
		@media (min-width: theme('screens.xl')) {
			min-width: 300px;
		}
	}
}
.select {
	label {
		@apply text-grey-dark appearance-none border rounded-2xl py-4 px-6 border-grey;
		svg {transition: transform 0.2s ease-in-out;}
	}
	.select-list {
		@apply border-b border-l border-r border-grey rounded-b-2xl pt-4;
		width: calc(100% + 2px);
		transform: translateX(-1px);
		li {
			&:first-child {
				@apply mt-px;
			}
		}
		.list-item {
			@apply flex bg-white border-b border-grey px-6 py-4 cursor-pointer text-grey-dark;
			&:last-child {
				@apply border-b-0;
			}
			&:hover, &:focus {
				@apply bg-blue-light text-blue;
				p {@apply text-blue;}
			}
		}
	}
	&:focus {
    @apply outline-none bg-white;
		label {
			@apply border-blue;
		}
  }
  &.disabled {
    @apply border-0 bg-grey-lighter;
  }
	&.open {
		@apply z-50;
		label {
			@apply shadow-sm;
			svg {
				transform: rotate(180deg);
				transition: transform 0.2s ease-in-out;
			}
		}
		.select-list {
			@apply z-50;
		}
	}
}

.label-select {
	li.label {
		&:first-child {
			@apply rounded-t-2xl;
		}
		&:last-child {
			@apply rounded-b-2xl;
		}
	}
}

//Menu
.options-container {
	@apply z-40;
	@media (max-width: theme('screens.lg')) {
		@apply w-screen h-screen left-0 top-0 fixed bg-black bg-opacity-25;
	}
	@media (min-width: theme('screens.lg')) {
		@apply absolute left-0 w-full;
		margin-top: 50px;
	}
}
.options-menu {
	@apply bg-white rounded-2xl flex flex-col border border-grey text-left shadow z-40;
	.option {
		@apply w-full text-base text-grey-dark border-b border-grey p-4 bg-white font-roboto font-normal !important;
		&:first-child {
			@apply pt-5 rounded-t-2xl !important;
		}
		&:last-child {
			@apply pb-5 rounded-b-2xl border-b-0 !important;
		}
		&:hover, &:focus {
			@apply text-blue bg-blue-light !important;
		}
	}
}

.menu {
	button, .round-button {
		&.active {
			@apply z-50;
		}
	}
	.button-menu {
		@media (min-width: theme('screens.md')) and (max-width: theme('screens.lg')) {
			&> button,&> a {
				@apply mb-6;
			}
		}
	}
	.options-menu {
		@media (max-width: theme('screens.md')) {
			width: calc(100% - 2rem);
		}
		@media (min-width: theme('screens.md')) and (max-width: theme('screens.lg')) {
			width: calc(100% - 98px);
			margin-left: 82px;
		}
	}
}

//ToggleBox
.latest-offers {
	@apply flex-grow;
	&.closed {
		@apply flex-grow-0;
	}
}
.box-header {
  @apply cursor-pointer flex flex-wrap items-center border-b border-grey w-full px-4 py-4;
  p {@apply font-mont font-medium mr-4;}
  &.closed {
    @apply border-0;
  }
  @media (min-width: theme('screens.md')) {
    @apply px-7;
  }
}
.box {
  @apply border border-grey rounded-2xl;
}
.box-error {
  @apply border-orange-light;
}

//ToggleRow
.row {
	@apply border-b border-grey;
	.row-header {
		@apply flex px-4 py-3 border-b border-grey;
		&.closed {
			@apply border-b-0;
		}
		@media (min-width: theme('screens.md')) {
			@apply px-7;
		}	
	}
	&:last-child {
		@apply border-b-0;
	}
}

//datepicker
.react-datepicker {
	@apply border-grey rounded-2xl text-grey-dark !important;
	width: calc(100% + 2px);
	transform: translateX(1px);
	.react-datepicker__navigation {
		top: 15px !important;
	}
	.react-datepicker__month-container {
		@apply w-full text-grey-dark !important;
		.react-datepicker__header {
			@apply bg-grey-lighter border-grey rounded-t-2xl pt-3 !important;
			.react-datepicker__current-month {
				@apply text-grey-dark font-mont font-medium !important;
			}
			.react-datepicker__day-names, .react-datepicker__day-name {
				@apply text-grey-dark m-1 !important;
			}
		}
		.react-datepicker__month {
			@apply text-grey-dark !important;
			.react-datepicker__week {
				.react-datepicker__day {
					@apply text-grey-dark m-1 !important;
					&.react-datepicker__day--today {
						@apply font-normal bg-grey-light rounded-full;
					}
					&.react-datepicker__day--selected, &.react-datepicker__day--keyboard-selected {
						@apply rounded-full bg-blue text-white !important;
					}
					&.react-datepicker__day--in-range {
						@apply rounded-full bg-blue-light text-blue !important;
						&.react-datepicker__day--range-start, &.react-datepicker__day--range-end {
							@apply bg-blue text-white !important;
						}
					}
				}
			}
		}
	}
	@media (min-width: theme('screens.md')) {
    width: 360px;
		transform: translateX(0);
  }
}

.date-picker-cal {
	@apply shadow mt-4 right-0 absolute !important;
	@media (min-width: theme('screens.md')) {
		right: 1.5rem !important;
	}
}
