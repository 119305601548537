.wardrobe-container {
  background-color: #C9C5BC;
  background-image: url('../../../assets/floor.jpg') ;
  background-size: 100vw 15%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.room-wrapper {
  width: 100%;
  height: 100%;
  perspective: 440px;

  .room {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-50px);

    .room__face {
      position: absolute;

      &--front {
        transform: rotateY(0deg) translateZ(50px);
      }

      &--back {
        background: url('../../../assets/list.png') bottom repeat-x, radial-gradient(#CECAC2, #CFCBC3 30%, #BDB9B0);
      }

      &--left {
        left: 50px;
        background: url('../../../assets/list.png') bottom no-repeat, linear-gradient(-90deg,#C5C1B9, #C8C4BB 65%, #BDB9B0); 
      }

      &--right {
        background: url('../../../assets/list.png') bottom no-repeat, linear-gradient(-90deg, #BDB9B0, #D1CDC5 65%, #CFCBC3);
      }

      &--top {
        background: linear-gradient(180deg, #BAB6AE, #BDB9B0 65%, #C5C1B8);
      }

      &--front,
      &--back {
        width: 100%;
        height: 100%;
      }

      &--left,
      &--right {
        height: 100%;
      }

      &--top {
        width: 100%;
        top: 50px;
      }
    }
  }
}

.wardrobe-wrapper {
  height: 100%;
  perspective: 440px;

  .wardrobe {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-75px);

    .wardrobe__face {
      position: absolute;

      &--front {
        border: 1px solid #1D1211;
        transform: rotateY(0deg) translateZ(25px);
        transform-style: preserve-3d;

        &--grid {
          &:before,
          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            background-color: #1D1211;
            position: absolute;
            top: 0;
          }

          &:before {
            left: 33.3%;
          }

          &:after {
            left: 66.6%;
          }
        }
      }

      &--back {
        border: 1px dashed #1D1211;
      }

      &--left {
        left: 50px;
        transform: rotateY(-90deg) translateZ(75px);
      }

      &--front,
      &--back {
        width: 100%;
        height: 100%;
      }

      &--left,
      &--right {
        width: 50px;
        height: 100%;
        border-top: 1px dashed #1D1211;
        border-bottom: 1px dashed #1D1211;
      }
    }
  }
}

.wardrobe-wrapper.placement {
  &-full {
    @apply w-full m-auto;
  }

  &-center .wardrobe {
    @apply m-auto;
  }

  &-right .wardrobe {
    @apply ml-auto;
  }
}

.preserve-3d {
  transform-style: preserve-3d;
}

.door-track {
  &-2 {
    margin-top: 3px;
  }

  &-3 {
    margin-top: 6px;
  }
}

@screen lg {
  .door-track {
    &-2 {
      margin-top: 5px;
    }
  
    &-3 {
      margin-top: 10px;
    }
  }
}